import '@fortawesome/fontawesome-svg-core/styles.css'
import 'tailwindcss/tailwind.css'
import '../../../global.css'

import { config } from '@fortawesome/fontawesome-svg-core'
import { withAuth } from '@invisible/authentication/client'
import { withAbilityContext } from '@invisible/authorization/client'
import { createApp } from '@invisible/common/providers'
import { withTRPC } from '@invisible/trpc/client'
import { LicenseInfo } from '@mui/x-license'

import { Layout } from '../components/Layout'

LicenseInfo.setLicenseKey(
  '124b228422f7188d38cd9498c788f9fbTz0xMDcwNzAsRT0xNzQ4NTYzMTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
)

config.autoAddCss = false // TODO: get rid of all fontawesome icons and then remove this

export default createApp({ Layout: withAbilityContext(withAuth(withTRPC(Layout))) })
